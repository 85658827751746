.layout {
  position: relative;
  display: flex;
  height: 100vh;
  transition: opacity 0.15s cubic-bezier(0.39, 0.575, 0.565, 1);
  flex-direction: column; }
  .layout--faded .main-stage__container > .grid {
    opacity: .15;
    pointer-events: none; }
  .layout__header-and-content {
    display: flex;
    flex-grow: 1;
    flex-direction: column; }
  .layout__sidebar-and-content {
    display: flex;
    flex-grow: 1;
    flex-direction: row; }
  .layout__content {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    padding-bottom: 10px; }
