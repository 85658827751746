.startshift {
  display: flex;
  height: 100%;
  background-color: #febc0c; }
  .startshift .grid-content {
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    text-align: center; }
  .startshift__title {
    margin-top: auto;
    font-size: 2.5em;
    font-weight: bold;
    color: #fff; }
  .startshift__instructions-text:last-child {
    margin-bottom: 20px; }
  .startshift__break {
    height: 30px; }
    .startshift__break .cell {
      position: absolute;
      width: 100%;
      left: 0;
      font-size: 1.5em;
      font-weight: 300;
      text-align: center !important; }
  .startshift__actions {
    border-collapse: separate;
    border-spacing: 20px 5px;
    margin-bottom: auto; }
    .startshift__actions--secondary {
      padding: 20px;
      margin-bottom: 10px; }
      .startshift__actions--secondary .action-button {
        font-size: 0.75em; }
    .startshift__actions .row:first-child .cell {
      padding-bottom: 40px; }
    .startshift__actions .cell:first-child {
      text-align: right; }
    .startshift__actions .action-button {
      font-size: 0.75em;
      width: 100%;
      margin: 10px 0; }
  .startshift .timer {
    margin: 20px 0; }
  .startshift .waves {
    transform: scale(1.015);
    transform-origin: center bottom; }
