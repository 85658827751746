.login {
  display: flex;
  min-width: 100vw;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.login-buttons {
  font-size: 0.875em; }
  .login-buttons .action-button {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 20em; }

.login-form {
  font-size: 0.875em;
  color: #8489c3; }
  .login-form .textfield {
    display: block;
    width: 100%;
    margin: 10px 0;
    color: #303133; }

.login-content {
  max-height: 0;
  overflow: hidden;
  text-align: center;
  animation: reveal-content 0.6s 0.6s;
  animation-fill-mode: forwards; }
  .login-content__welcome {
    margin: 40px 0 20px;
    font-size: 2.5em;
    font-weight: bold; }
  .login-content__help, .login-content__instructions {
    margin: 20px 0;
    font-size: 0.875em;
    font-weight: bold;
    text-transform: uppercase; }
  .login-content__help {
    margin-top: 40px; }
  .login-content__unauthorized {
    margin: 20px 0;
    font-size: 0.875em;
    font-weight: bold;
    text-transform: uppercase;
    color: #e04341; }

@keyframes reveal-content {
  0% {
    max-height: 0; }
  99% {
    overflow: hidden; }
  100% {
    max-height: 400px;
    overflow: visible; } }
