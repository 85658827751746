.interaction-tab {
  position: relative;
  display: inline-block;
  padding: 0 20px;
  line-height: 30px; }
  .interaction-tab--active {
    background-color: #fff; }
    .interaction-tab--active .interaction-tab__label {
      font-weight: bold; }
  .interaction-tab--single {
    width: 100%; }
  .interaction-tab .action-button--close {
    display: inline-block;
    padding: 5px;
    margin-left: 5px;
    margin-right: -10px;
    opacity: .5;
    vertical-align: middle;
    outline: none; }
    .interaction-tab .action-button--close .icon-wrapper {
      font-size: 0.375em; }
  .interaction-tab .action-button--text {
    display: inline-block;
    padding: 5px 0;
    vertical-align: middle;
    font-size: 0.6875em;
    text-transform: uppercase;
    line-height: 1;
    color: #303133;
    outline: none; }
