.header {
  position: relative;
  display: flex;
  min-height: 54px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  flex: 0 0 auto;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap; }
  .header--no-taskmanager .user-profile {
    margin-left: 20px !important;
    margin-right: auto !important; }
  .header .panel-title,
  .header .header__group {
    flex: 0; }
  .header .header__group {
    margin: 5px 2px; }
    .header .header__group button {
      padding: 5px 7px;
      outline: none; }

.toolbar-mode {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #41518d;
  border-bottom-left-radius: 50%; }
  .toolbar-mode .action-button {
    padding: 2px 2px 5px 5px;
    color: #fff; }
