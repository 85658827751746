.dropdown {
  position: relative;
  display: inline-block;
  border: 1px solid currentColor;
  border-radius: 5px; }
  .dropdown--full-width {
    width: 100%; }
    .dropdown--full-width .dropdown__select {
      width: 100%; }
  .dropdown__select-container {
    display: block; }
  .dropdown__select {
    padding: 10px 20px 10px 10px;
    color: currentColor;
    border: 0;
    background-color: transparent;
    appearance: none; }
    .dropdown__select option {
      text-transform: uppercase;
      appearance: none; }
  .dropdown__arrow {
    margin: -0.75em 10px 0;
    position: absolute;
    top: 50%;
    right: 0;
    pointer-events: none; }

.dropdown-with-label {
  display: block; }
  .dropdown-with-label--full-width {
    width: 100%; }
    .dropdown-with-label--full-width .dropdown__select {
      width: 100%; }

.dropdown-label {
  display: block;
  padding-bottom: 6.6px;
  font-weight: bold; }
