.interaction-tabs {
  display: flex;
  width: 100%;
  height: 30px;
  z-index: 1;
  background-color: #b1d6d9;
  flex: 0 0 auto;
  justify-content: space-between; }
  .interaction-tabs--color-0 {
    background-color: #b1d6d9; }
  .interaction-tabs--color-1 {
    background-color: #89bc9a; }
  .interaction-tabs--color-2 {
    background-color: #e6b595; }
  .interaction-tabs--color-3 {
    background-color: #e5949d; }
  .interaction-tabs--color-4 {
    background-color: #e5dd95; }
  .interaction-tabs--color-5 {
    background-color: #a0d190; }
  .interaction-tabs--color-6 {
    background-color: #e295c0; }
  .interaction-tabs--color-7 {
    background-color: #947eab; }
  .interaction-tabs--singletab {
    background-color: transparent; }
    .interaction-tabs--singletab.interaction-tabs--call-active {
      background-color: #ebf4f4; }
    .interaction-tabs--singletab .interaction-tab--active {
      background-color: transparent; }
  .interaction-tabs--start-shift {
    border-bottom: 1px solid #fff;
    background-color: #febc0c; }
  .interaction-tabs__visible {
    width: 0;
    overflow: hidden;
    white-space: nowrap;
    flex: 1 1 auto; }
    .interaction-tabs__visible .interaction-tab {
      float: left; }
  .interaction-tabs .submenu > .action-button {
    height: 100%;
    padding: 0 5px;
    opacity: .5;
    outline: none; }
    .interaction-tabs .submenu > .action-button:focus, .interaction-tabs .submenu > .action-button:active {
      opacity: 1; }
    .interaction-tabs .submenu > .action-button .icon-wrapper {
      height: 12px;
      width: 12px; }
  .interaction-tabs .submenu .submenu__panel .interaction-tab {
    display: block;
    margin: 10px 0;
    float: none; }
