.panel-title {
  position: relative;
  width: 100%;
  padding: 15px 20px;
  font-size: 0.75em;
  text-align: left;
  text-transform: uppercase;
  color: #41518d;
  white-space: nowrap;
  outline: none;
  border: 0;
  background-color: transparent;
  flex: 0 0 auto; }
  .panel-title__label {
    padding-left: 10px;
    vertical-align: middle;
    line-height: 1;
    letter-spacing: 0.04167em; }
    @media all and (max-width: 900px) {
      .panel-title__label {
        display: none; } }
  .panel-title .icon-wrapper {
    width: 2em;
    height: 2em; }
