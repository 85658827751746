.logo {
  display: flex;
  width: 40%;
  min-width: 200px;
  max-width: 350px;
  align-items: center;
  opacity: 0;
  transform: translateY(150px); }
  .logo--fade-in {
    animation: logo-fade-in 1s cubic-bezier(0.39, 0.575, 0.565, 1);
    animation-fill-mode: forwards; }
  .logo--move-up {
    opacity: 1;
    transform: translateY(0); }
    .logo--move-up .logo__shape {
      left: 50%;
      transform: translateX(-50%); }
    .logo--move-up .logo__type {
      transform: scale(0);
      opacity: 0; }
  .logo__shape {
    position: relative;
    flex: 1 1 33%;
    left: 0;
    transition: left 0.6s cubic-bezier(0.39, 0.575, 0.565, 1), transform 0.6s cubic-bezier(0.39, 0.575, 0.565, 1); }
  .logo__type {
    margin: 0 1.5vw;
    overflow: hidden;
    flex: 1 1 67%;
    transition: opacity 0.6s cubic-bezier(0.39, 0.575, 0.565, 1), transform 0.6s cubic-bezier(0.39, 0.575, 0.565, 1); }
  .logo img {
    width: 100%;
    height: auto; }

@keyframes logo-fade-in {
  0% {
    opacity: 0;
    transform: translateY(150px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }
