.incoming-panel {
  display: flex;
  width: 100%;
  height: 100%;
  color: #fff;
  flex-direction: row;
  justify-content: center;
  align-items: center; }
  .incoming-panel__left, .incoming-panel__right {
    display: flex;
    height: 100%;
    padding: 20px;
    flex: 0 0 50%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box; }
  .incoming-panel__left {
    border-right: 1px solid #a9a1ce; }
  .incoming-panel__title {
    font-size: 1.875em;
    font-weight: 300; }
  .incoming-panel .action-button {
    margin: 20px 0;
    font-size: 0.75em; }
  .incoming-panel .timer {
    margin: 20px 0; }
  .incoming-panel .interaction-icon {
    margin: 10px 0; }
    .incoming-panel .interaction-icon .icon-wrapper {
      height: 2em;
      width: 2em; }
  .incoming-panel .profile-image {
    height: 120px;
    width: 120px;
    margin: 40px 0;
    overflow: hidden;
    color: #7bb8b5;
    border-radius: 60px;
    background-color: #cee5e3;
    transform: translateZ(0); }
    .incoming-panel .profile-image img,
    .incoming-panel .profile-image .icon-wrapper {
      height: 100%;
      width: 100%; }
    .incoming-panel .profile-image .icon-wrapper {
      margin-top: 10px; }
  .incoming-panel .block {
    margin: 20px 0;
    text-align: center; }
    .incoming-panel .block:nth-of-type(n+2) .incoming-panel__info {
      font-size: 1.125em;
      word-wrap: break-word; }
  .incoming-panel__label {
    padding: 5px 0;
    opacity: .5;
    font-size: 0.75em;
    text-transform: uppercase; }
  .incoming-panel__info {
    font-size: 1.125em;
    font-weight: 500; }
    .incoming-panel__info--name {
      font-size: 1.5em;
      font-weight: 400; }
