@import url(~normalize.css/normalize.css);
@font-face {
  font-family: "Roboto";
  font-weight: 300;
  font-style: normal;
  src: local("Roboto Light"), local("Roboto-Light"), url("assets/fonts/roboto/roboto-v16-latin-300.woff2") format("woff2"), url("assets/fonts/roboto/roboto-v16-latin-300.woff") format("woff"); }

@font-face {
  font-family: "Roboto";
  font-weight: 400;
  font-style: normal;
  src: local("Roboto Regular"), local("Roboto-Regular"), url("assets/fonts/roboto/roboto-v16-latin-regular.woff2") format("woff2"), url("assets/fonts/roboto/roboto-v16-latin-regular.woff") format("woff"); }

@font-face {
  font-family: "Roboto";
  font-weight: 500;
  font-style: normal;
  src: local("Roboto SemiBold"), local("Roboto-SemiBold"), url("assets/fonts/roboto/roboto-v16-latin-500.woff2") format("woff2"), url("assets/fonts/roboto/roboto-v16-latin-500.woff") format("woff"); }

@font-face {
  font-family: "Roboto";
  font-weight: 700;
  font-style: normal;
  src: local("Roboto Bold"), local("Roboto-Bold"), url("assets/fonts/roboto/roboto-v16-latin-700.woff2") format("woff2"), url("assets/fonts/roboto/roboto-v16-latin-700.woff") format("woff"); }

html,
body {
  min-width: 100vw;
  min-height: 100vh;
  padding: 0;
  margin: 0; }

body {
  font-family: 'Roboto', sans-serif; }
  body.js-focus-ring *:focus:not(.focus-ring) {
    outline-width: 0; }
