.timer {
  padding: 0.5em 0;
  font-size: 2.5em;
  font-weight: 300; }
  .timer--hours {
    font-size: 1.875em; }

.small-timer {
  padding: 0.5em 0;
  font-size: 2.5em;
  font-weight: 550; }
  .small-timer--hours {
    font-size: 1.25em; }

.user-timer {
  padding: 0.16667em 0;
  font-size: 0.75em;
  color: #41518d; }
  .user-timer--hours {
    font-size: 0.75em; }
