.agent-dash {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }
  .agent-dash__container {
    position: relative;
    margin-top: 10px;
    margin-bottom: 30px;
    margin-left: 0.5rem;
    width: 40px; }
    .agent-dash__container-open {
      width: 250px; }
      @media (min-width: 1386px) {
        .agent-dash__container-open {
          width: 275px; } }
      @media (min-width: 1600px) {
        .agent-dash__container-open {
          width: 300px; } }
  .agent-dash__open-btn {
    transition: background-color 150ms;
    font-size: 1rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    color: white;
    background-color: #41518d;
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem; }
    .agent-dash__open-btn:hover {
      background-color: #5265af; }
