.toolbar-shadow {
  box-shadow: 0 5px 10px 0 rgba(140, 133, 225, 0.25); }

.table {
  display: table; }
  .table__header {
    display: table-header-group; }
  .table__body {
    display: table-row-group; }

.row {
  display: table-row; }

.cell {
  display: table-cell; }

button {
  padding: 0;
  margin: 0;
  color: currentColor;
  cursor: pointer;
  border: 0;
  background-color: transparent; }

.card-shadow {
  box-shadow: 0 10px 20px 0 rgba(140, 133, 225, 0.3), 0 20px 10px -10px rgba(140, 133, 225, 0.3); }
  .card-shadow--voffset {
    box-shadow: 0 20px 20px 0 rgba(140, 133, 225, 0.3), 0 20px 10px -10px rgba(140, 133, 225, 0.3); }
  .card-shadow--hoffset {
    box-shadow: 13.2px 0 20px 0 rgba(140, 133, 225, 0.2), 5px 0 10px -10px rgba(140, 133, 225, 0.3); }

.toolbar-shadow--up {
  box-shadow: 0 -5px 10px 0 rgba(140, 133, 225, 0.25); }

.raised-shadow {
  box-shadow: 0 10px 40px 0 rgba(140, 133, 225, 0.75); }
  .raised-shadow--subtle {
    box-shadow: 0 10px 20px 0 rgba(140, 133, 225, 0.25); }

.toolbar-shadow {
  box-shadow: 0 5px 10px 0 rgba(140, 133, 225, 0.25); }

.scrollbar {
  max-height: 100%;
  box-sizing: border-box;
  scrollbar-face-color: #a9a1ce;
  scrollbar-track-color: #f6f5fa;
  overflow: auto; }
  .scrollbar::-webkit-scrollbar {
    width: 6.6px;
    height: 6.6px; }
  .scrollbar::-webkit-scrollbar-thumb {
    border-radius: 6.6px;
    background-color: #a9a1ce; }
  .scrollbar::-webkit-scrollbar-track {
    border-radius: 6.6px;
    background-color: #f6f5fa; }
  .scrollbar:not(.scrollbar--full-width) {
    min-width: calc(100% - 20px);
    margin: 10px;
    box-sizing: border-box; }
  .scrollbar--transparent {
    scrollbar-face-color: #a9a1ce;
    scrollbar-track-color: transparent;
    overflow: auto; }
    .scrollbar--transparent::-webkit-scrollbar {
      width: 6.6px;
      height: 6.6px; }
    .scrollbar--transparent::-webkit-scrollbar-thumb {
      border-radius: 6.6px;
      background-color: #a9a1ce; }
    .scrollbar--transparent::-webkit-scrollbar-track {
      border-radius: 6.6px;
      background-color: transparent; }

.toolbar-shadow {
  box-shadow: 0 5px 10px 0 rgba(140, 133, 225, 0.25); }

@keyframes marching-ants-anim {
  0% {
    background-position: 0 0, 0 100%, 0 0, 100% 0; }
  100% {
    background-position: 40px 0, -40px 100%, 0 -40px, 100% 40px; } }

.theme {
  min-height: 100vh; }
