.main-stage {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column; }
  .main-stage--faded .main-stage__container > .grid {
    opacity: .4;
    cursor: not-allowed; }
    .main-stage--faded .main-stage__container > .grid > * {
      pointer-events: none; }
  .main-stage__container {
    position: relative;
    display: flex;
    flex: 1 0 200px; }
  .main-stage__add-caseinfo {
    display: block;
    padding: 3px 0 0 26px;
    opacity: .5;
    text-transform: none;
    color: #303133; }
  .main-stage__actionButton {
    font-size: 0.9em;
    margin-top: 10px;
    margin-right: 10px;
    color: #41518d; }
  .main-stage__add-bar {
    display: flex;
    color: #404aa6;
    transition: color 0.15s cubic-bezier(0.39, 0.575, 0.565, 1);
    align-items: center;
    justify-content: center; }
    .main-stage__add-bar:hover {
      color: #fff; }
    .main-stage__add-bar .submenu {
      display: flex;
      height: 100%;
      width: 100%;
      min-width: 20px;
      min-height: 20px;
      justify-content: center;
      align-items: center; }
      .main-stage__add-bar .submenu > .action-button {
        font-size: 0.5em; }
    .main-stage__add-bar .submenu--active > .action-button {
      background-color: transparent; }
    .main-stage__add-bar--side {
      padding: 10px 0;
      flex: 0 0 20px; }
    .main-stage__add-bar--bottom {
      padding: 0 30px 0 10px;
      flex: 0 0 20px; }
    .main-stage__add-bar > .action-button {
      height: 100%;
      width: 100%;
      min-width: 20px;
      min-height: 20px;
      padding: 0;
      margin: 0;
      font-size: 0.5em;
      flex: 1 0 100%; }

.panel-tab-submenu-item {
  display: flex;
  position: relative;
  padding-left: 10px; }
  .panel-tab-submenu-item::before {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 5px;
    content: '';
    background-color: #a0cdd1;
    transform: scale(1, 0.7);
    transition: transform 0.1s cubic-bezier(0.39, 0.575, 0.565, 1); }
  .panel-tab-submenu-item--color-0::before {
    background-color: #a0cdd1; }
  .panel-tab-submenu-item--color-0__badge {
    background-color: #a0cdd1; }
  .panel-tab-submenu-item--color-1::before {
    background-color: #79b38c; }
  .panel-tab-submenu-item--color-1__badge {
    background-color: #79b38c; }
  .panel-tab-submenu-item--color-2::before {
    background-color: #e1a780; }
  .panel-tab-submenu-item--color-2__badge {
    background-color: #e1a780; }
  .panel-tab-submenu-item--color-3::before {
    background-color: #e0808a; }
  .panel-tab-submenu-item--color-3__badge {
    background-color: #e0808a; }
  .panel-tab-submenu-item--color-4::before {
    background-color: #e0d781; }
  .panel-tab-submenu-item--color-4__badge {
    background-color: #e0d781; }
  .panel-tab-submenu-item--color-5::before {
    background-color: #90ca7e; }
  .panel-tab-submenu-item--color-5__badge {
    background-color: #90ca7e; }
  .panel-tab-submenu-item--color-6::before {
    background-color: #dd81b4; }
  .panel-tab-submenu-item--color-6__badge {
    background-color: #dd81b4; }
  .panel-tab-submenu-item--color-7::before {
    background-color: #876fa1; }
  .panel-tab-submenu-item--color-7__badge {
    background-color: #876fa1; }

.hidden {
  display: none; }
