.textfield {
  padding: 10px;
  font-weight: 600;
  color: inherit;
  border: 1px solid currentColor;
  border-radius: 3px;
  background-color: transparent;
  transition: border-color 0.15s cubic-bezier(0.39, 0.575, 0.565, 1), background-color 0.15s cubic-bezier(0.39, 0.575, 0.565, 1);
  box-sizing: border-box; }
  .textfield::placeholder {
    font-weight: normal;
    color: rgba(132, 137, 195, 0.8); }
  .textfield:focus {
    outline: 0; }
  .textfield--raised:focus {
    box-shadow: 0 10px 20px 0 rgba(140, 133, 225, 0.25); }
  .textfield--full-width {
    width: 100%; }
  .textfield:not(.textfield--stroked):focus {
    border-color: #fff;
    background-color: #fff; }
  .textfield--phoneInput {
    color: #7bb8b5; }
  .textfield--valid-value {
    color: #7bb8b5; }
  .textfield--invalid-value {
    color: #e0808a; }

.textfield-with-label {
  display: block; }
  .textfield-with-label--full-width {
    width: 100%; }

.textfield-label {
  display: block;
  padding-bottom: 6.6px;
  font-weight: bold; }
