.case-header {
  position: relative;
  display: flex;
  height: 30px;
  padding: 5px 5px 5px 20px;
  background-color: #a0cdd1;
  z-index: 2;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box; }
  .case-header--color-0 {
    background-color: #a0cdd1; }
  .case-header--color-1 {
    background-color: #79b38c; }
  .case-header--color-2 {
    background-color: #e1a780; }
  .case-header--color-3 {
    background-color: #e0808a; }
  .case-header--color-4 {
    background-color: #e0d781; }
  .case-header--color-5 {
    background-color: #90ca7e; }
  .case-header--color-6 {
    background-color: #dd81b4; }
  .case-header--color-7 {
    background-color: #876fa1; }
  .case-header--incoming {
    border-bottom: 1px solid #d6dcff;
    background-color: #404aa6; }
    .case-header--incoming .case-header__label {
      color: #fff; }
  .case-header__label {
    font-size: 0.75em;
    font-weight: 500;
    line-height: 1;
    color: #303133;
    justify-self: flex-start;
    flex: 1; }
  .case-header__layout-mode, .case-header__extract, .case-header__close {
    margin-left: 10px;
    z-index: 1;
    flex: 0; }
    .case-header__layout-mode .submenu > .action-button .icon-wrapper,
    .case-header__layout-mode > .action-button .icon-wrapper, .case-header__extract .submenu > .action-button .icon-wrapper,
    .case-header__extract > .action-button .icon-wrapper, .case-header__close .submenu > .action-button .icon-wrapper,
    .case-header__close > .action-button .icon-wrapper {
      opacity: .5;
      font-size: 0.75em; }
  .case-header__layout-mode .submenu {
    position: static; }
  .case-header__layout-mode .submenu--active > .action-button {
    background-color: transparent; }
