.grid-item {
  position: absolute;
  max-height: 100%;
  transition: width 0.15s cubic-bezier(0.075, 0.82, 0.165, 1), height 0.15s cubic-bezier(0.075, 0.82, 0.165, 1), top 0.15s cubic-bezier(0.075, 0.82, 0.165, 1), left 0.15s cubic-bezier(0.075, 0.82, 0.165, 1);
  box-sizing: border-box; }
  @media all and (max-width: 900px) {
    .grid-item {
      transition: none; } }
  .grid-item--gutter {
    padding: 10px; }
  .grid-item--interaction {
    display: flex;
    min-height: 30px;
    overflow: hidden;
    flex-direction: column; }
  .grid-item--case {
    min-height: 40px; }
  .grid-item > section {
    display: flex;
    flex: 1 1 100%; }

.grid-content {
  display: flex;
  flex: 1 1 auto;
  max-height: 96%; }
  .grid-content--scroll {
    max-height: 100%; }
