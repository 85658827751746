.submenu--floating.submenu--floating-side .submenu__panel::before, .submenu--floating.submenu--floating-bottom .submenu__panel::before {
  box-shadow: 0 5px 10px 0 rgba(140, 133, 225, 0.25); }

.submenu {
  position: relative;
  white-space: nowrap;
  flex: 0 0 auto; }
  .submenu--active {
    z-index: 100; }
    .submenu--active > .action-button {
      opacity: 1;
      background-color: #fff; }
  .submenu--dividers .submenu__panel .action-button {
    border-bottom: 1px solid #a9a1ce; }
    .submenu--dividers .submenu__panel .action-button:last-child {
      border-bottom: 0; }
  .submenu--floating.submenu--floating-side .submenu__panel {
    top: 50%;
    right: 100%;
    margin-right: 5px;
    transform: translateY(-50%); }
    .submenu--floating.submenu--floating-side .submenu__panel::before {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      height: 14px;
      width: 14px;
      margin-top: -7px;
      margin-right: -7px;
      z-index: -1;
      transform: rotate(-45deg); }
    .submenu--floating.submenu--floating-side .submenu__panel::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      height: 0;
      width: 0;
      border-bottom: 10px solid transparent;
      border-left: 10px solid #fff;
      border-top: 10px solid transparent;
      margin-top: -10px;
      margin-right: -10px; }
  .submenu--floating.submenu--floating-bottom .submenu__panel {
    top: auto;
    bottom: 100%;
    left: 50%;
    right: auto;
    margin-bottom: 5px;
    transform: translateX(-50%); }
    .submenu--floating.submenu--floating-bottom .submenu__panel::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      height: 14px;
      width: 14px;
      margin-bottom: -7px;
      margin-left: -7px;
      z-index: -1;
      transform: rotate(-45deg); }
    .submenu--floating.submenu--floating-bottom .submenu__panel::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      height: 0;
      width: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #fff;
      margin-bottom: -10px;
      margin-left: -10px; }
  .submenu:not(.submenu--active) > .action-button:hover {
    opacity: 1; }
  .submenu__panel {
    position: absolute;
    top: 100%;
    right: 0; }
    .submenu__panel .action-button {
      display: block;
      width: 100%;
      padding: 5px 0;
      margin: 5px 0;
      text-align: left;
      text-transform: capitalize; }
  .submenu__menuitems {
    position: relative;
    padding: 10px 20px;
    z-index: 2;
    font-size: 0.75em;
    color: #404aa6;
    background-color: #fff; }
    .submenu__menuitems .icon-wrapper {
      height: 1.3em;
      width: 1.3em; }
