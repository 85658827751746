.panelset-container {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
  outline: none;
  border-radius: 0;
  background-color: #fff;
  transition: opacity 0.15s cubic-bezier(0.39, 0.575, 0.565, 1), transform 0.15s cubic-bezier(0.39, 0.575, 0.565, 1);
  flex-direction: column; }
  .panelset-container--show-droptarget {
    background-color: transparent; }
    .panelset-container--show-droptarget div:not(:last-child) {
      visibility: hidden; }
    .panelset-container--show-droptarget .case-container__droptarget {
      visibility: visible;
      opacity: .5; }
  .panelset-container.react-draggable-dragging {
    z-index: 3;
    opacity: .9;
    transition: opacity 0.15s cubic-bezier(0.39, 0.575, 0.565, 1); }
    .panelset-container.react-draggable-dragging::after {
      display: block; }
  .panelset-container::after {
    content: '';
    display: none;
    z-index: 3;
    background-color: rgba(255, 255, 255, 0.75);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-position: 0 0, 0 100%, 0 0, 100% 0;
    background-size: 20px 2px, 20px 2px, 2px 20px, 2px 20px;
    animation: marching-ants-anim 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    background-image: linear-gradient(to right, #a9a1ce 50%, transparent 50%), linear-gradient(to right, #a9a1ce 50%, transparent 50%), linear-gradient(to bottom, #a9a1ce 50%, transparent 50%), linear-gradient(to bottom, #a9a1ce 50%, transparent 50%); }
  .panelset-container__droptarget {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    visibility: hidden;
    z-index: 3;
    opacity: 0;
    color: #a9a1ce;
    pointer-events: none;
    border: 1px solid #a9a1ce;
    background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="none" viewBox="0 0 48 48" xml:space="preserve"><line vector-effect="non-scaling-stroke" fill="none" stroke="#a9a1ce" stroke-miterlimit="10" x1="0" y1="0" x2="48" y2="48"/><line vector-effect="non-scaling-stroke" fill="none" stroke="#a9a1ce" stroke-miterlimit="10" x1="48" y1="0" x2="0" y2="48"/></svg>');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    transition: opacity 0.15s cubic-bezier(0.39, 0.575, 0.565, 1), visibility 0.15s cubic-bezier(0.39, 0.575, 0.565, 1);
    box-sizing: border-box; }
