.task-manager {
  position: relative;
  margin-left: 0;
  margin-right: auto;
  z-index: 2;
  align-self: flex-start; }
  .task-manager--open .task-manager__list {
    width: 220px; }
  .task-manager--incoming:not(.task-manager--open) .panel-title {
    display: none; }
  .task-manager__list {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 0;
    display: flex;
    padding-top: 40px;
    overflow: hidden;
    background: linear-gradient(to bottom, #e5efff 0%, #d6dcff 25%, #ded7fe 75%, #d6dcff 100%);
    transition: width 0.15s cubic-bezier(0.39, 0.575, 0.565, 1);
    box-sizing: border-box; }
  .task-manager__list-content {
    display: flex;
    min-width: 220px;
    white-space: nowrap;
    flex-direction: column;
    justify-content: space-between; }
  .task-manager__actions {
    margin: 0 20px;
    margin-bottom: auto;
    color: #41518d; }
    .task-manager__actions .dropdown-with-label {
      margin: 20px 0;
      overflow: hidden; }
    .task-manager__actions .dropdown-label {
      font-size: 0.75em;
      text-transform: uppercase;
      color: #303133; }
    .task-manager__actions .action-button {
      width: 100%;
      font-size: 0.75em; }
  .task-manager__customer {
    margin: 20px;
    white-space: normal; }
    .task-manager__customer .action-button {
      width: 100%;
      margin-bottom: 5px;
      font-size: 0.875em;
      text-align: left;
      color: #41518d;
      justify-content: flex-end;
      flex-wrap: wrap; }
      .task-manager__customer .action-button .icon-wrapper {
        margin-left: auto; }
    .task-manager__customer .customer-id {
      display: block;
      padding-right: 20px;
      opacity: .5;
      font-size: 0.75em;
      font-weight: bold; }
  .task-manager__close-task {
    padding: 0 20px 20px; }
    .task-manager__close-task .action-button {
      width: 100%;
      font-size: 0.8125em; }
