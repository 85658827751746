.user-profile {
  white-space: nowrap; }
  .user-profile__image-container {
    position: relative; }
  .user-profile__status__red::after {
    content: '';
    position: absolute;
    right: -5px;
    bottom: 5px;
    height: 13.2px;
    width: 13.2px;
    border-radius: 50%;
    background-color: #e04341; }
  .user-profile__status__green::after {
    content: '';
    position: absolute;
    right: -5px;
    bottom: 5px;
    height: 13.2px;
    width: 13.2px;
    border-radius: 50%;
    background-color: #90ca7e; }
  .user-profile__status__gray::after {
    content: '';
    position: absolute;
    right: -5px;
    bottom: 5px;
    height: 13.2px;
    width: 13.2px;
    border-radius: 50%;
    background-color: #85878c; }
  .user-profile__status__yellow::after {
    content: '';
    position: absolute;
    right: -5px;
    bottom: 5px;
    height: 13.2px;
    width: 13.2px;
    border-radius: 50%;
    background-color: #febc0c; }
  .user-profile__image-container, .user-profile__details {
    display: inline-block;
    vertical-align: middle; }
  .user-profile__image {
    height: 40px;
    width: 40px;
    overflow: hidden;
    color: #41518d;
    border-radius: 50%;
    background-color: #cee5e3; }
    .user-profile__image img,
    .user-profile__image .icon-wrapper {
      height: 100%;
      width: 100%; }
    .user-profile__image .icon-wrapper {
      margin-top: 5px; }
  .user-profile__details {
    padding: 0 10px; }
  .user-profile__name {
    font-size: 0.875em;
    font-weight: bold;
    color: #303133; }
  .user-profile__mode {
    font-size: 0.75em;
    text-transform: lowercase;
    color: #41518d; }
  .user-profile__settings {
    font-size: 0.625em;
    text-transform: lowercase;
    color: #41518d; }
