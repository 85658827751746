.overlay {
  position: fixed !important;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  justify-content: center;
  align-items: center; }
  .overlay__panel {
    width: 500px;
    padding: 20px;
    text-align: center;
    color: #fff;
    background-color: #404aa6; }
  .overlay__icon {
    margin: 10px 0;
    font-size: 2em; }
  .overlay__title {
    margin: 10px 0;
    margin-bottom: 20px;
    font-size: 1.5em;
    font-weight: 300; }
  .overlay__subtitle {
    margin: 10px 0;
    margin-bottom: 20px; }
  .overlay__instructions {
    margin: 10px 0;
    font-size: 0.75em;
    font-weight: bold;
    text-transform: uppercase; }
  .overlay__content {
    margin: 10px 0; }
    .overlay__content .dropdown-with-label {
      display: inline-block;
      vertical-align: bottom;
      font-size: 0.875em;
      text-align: left; }
      .overlay__content .dropdown-with-label .dropdown-label {
        font-size: 0.75em;
        font-weight: bold;
        text-transform: uppercase; }
    .overlay__content .action-button {
      display: inline-block;
      margin: 0 10px;
      vertical-align: bottom;
      font-size: 0.875em; }
      .overlay__content .action-button:last-child {
        display: block;
        margin: 40px auto 0;
        font-size: 0.75em; }
  .overlay .incoming-panel .profile-image {
    margin-top: auto;
    margin-bottom: 0; }
  .overlay .incoming-panel .timer {
    margin: 0; }
