.interaction-icon {
  display: inline-block;
  padding: 0;
  margin-right: 6.6px;
  color: inherit;
  border: 0;
  background-color: transparent; }
  .interaction-icon--inbound .direction-arrow--inbound {
    display: inline-block; }
  .interaction-icon--outbound .direction-arrow--outbound {
    display: inline-block; }

.direction-arrow {
  position: absolute;
  display: none;
  margin-top: -5px;
  margin-left: 2px;
  font-weight: bold; }
  .direction-arrow--inbound {
    left: 0; }
