.interactions {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column; }
  .interactions__extend {
    position: absolute;
    z-index: 1;
    color: #fff;
    background-color: #404aa6;
    transition: width 0.15s cubic-bezier(0.39, 0.575, 0.565, 1), height 0.15s cubic-bezier(0.39, 0.575, 0.565, 1); }
    .interactions__extend .submenu {
      position: static; }
      .interactions__extend .submenu > .action-button {
        margin-top: -5px;
        margin-left: -5px; }
        .interactions__extend .submenu > .action-button::before {
          position: absolute;
          top: 0;
          left: 0;
          height: 20px;
          width: 20px;
          content: '';
          margin-top: -5px;
          margin-left: -5px;
          background-color: #404aa6; }
        .interactions__extend .submenu > .action-button .icon-wrapper {
          height: 10px;
          width: 10px; }
    .interactions__extend--side {
      height: 100%;
      width: 0;
      top: 0;
      right: 0; }
      .interactions__extend--side:hover {
        width: 20px; }
      .interactions__extend--side .submenu > .action-button {
        position: absolute;
        top: 50%;
        right: 5px; }
        .interactions__extend--side .submenu > .action-button::before {
          border-bottom-left-radius: 50%;
          border-top-left-radius: 50%; }
      .interactions__extend--side .submenu__panel {
        right: 30px !important; }
    .interactions__extend--bottom {
      height: 0;
      width: 100%;
      bottom: 0;
      left: 0; }
      .interactions__extend--bottom:hover {
        height: 20px; }
      .interactions__extend--bottom .submenu > .action-button {
        position: absolute;
        bottom: 5px;
        left: 50%; }
        .interactions__extend--bottom .submenu > .action-button::before {
          border-top-left-radius: 50%;
          border-top-right-radius: 50%; }
      .interactions__extend--bottom .submenu__panel {
        bottom: 30px !important; }
