.caselist-item {
  position: relative;
  display: block;
  padding: 15px 0;
  overflow: hidden;
  animation: zoomIn .6s; }
  @media all and (max-width: 900px) {
    .caselist-item {
      padding: 10px 0; } }
  .caselist-item::before {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 5px;
    content: '';
    background-color: #a0cdd1;
    transform: scale(1, 0.7);
    transition: transform 0.1s cubic-bezier(0.39, 0.575, 0.565, 1); }
  .caselist-item::after {
    content: '';
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 1px;
    z-index: 1;
    background: linear-gradient(to bottom, #e5efff 0%, #d6dcff 25%, #ded7fe 75%, #d6dcff 100%);
    background-attachment: fixed; }
  .caselist-item--color-0::before {
    background-color: #a0cdd1; }
  .caselist-item--color-0__badge {
    background-color: #a0cdd1; }
  .caselist-item--color-1::before {
    background-color: #79b38c; }
  .caselist-item--color-1__badge {
    background-color: #79b38c; }
  .caselist-item--color-2::before {
    background-color: #e1a780; }
  .caselist-item--color-2__badge {
    background-color: #e1a780; }
  .caselist-item--color-3::before {
    background-color: #e0808a; }
  .caselist-item--color-3__badge {
    background-color: #e0808a; }
  .caselist-item--color-4::before {
    background-color: #e0d781; }
  .caselist-item--color-4__badge {
    background-color: #e0d781; }
  .caselist-item--color-5::before {
    background-color: #90ca7e; }
  .caselist-item--color-5__badge {
    background-color: #90ca7e; }
  .caselist-item--color-6::before {
    background-color: #dd81b4; }
  .caselist-item--color-6__badge {
    background-color: #dd81b4; }
  .caselist-item--color-7::before {
    background-color: #876fa1; }
  .caselist-item--color-7__badge {
    background-color: #876fa1; }
  .caselist-item:hover::before {
    transform: scale(3, 0.7); }
  .caselist-item--stageposition {
    animation: none; }
  .caselist-item--active::before {
    transform: scale(1, 1); }
  .caselist-item--active:hover::before {
    transform: scale(3, 1); }
  .caselist-item--taskmanager-open .caselist-item__arrow {
    transform: rotate(-180deg); }
  .caselist-item--taskmanager-open::after {
    display: block; }
  .caselist-item__arrow {
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -0.75em;
    margin-right: 5px;
    outline: none;
    transform-origin: center center;
    transition: transform 0.3s cubic-bezier(0.39, 0.575, 0.565, 1); }
    @media all and (max-width: 900px) {
      .caselist-item__arrow {
        display: none; } }
  .caselist-item__button {
    position: relative;
    display: block;
    width: 100%;
    padding: 0 40px 0 20px;
    overflow: hidden;
    text-align: left;
    outline: none;
    box-sizing: border-box; }
    @media all and (max-width: 900px) {
      .caselist-item__button {
        padding: 0 20px; } }
  .caselist-item__customer-name {
    display: block;
    font-size: 0.75em;
    font-weight: bold;
    text-transform: uppercase;
    color: #303133;
    letter-spacing: 0.04167em;
    margin-left: 20px;
    text-align: left; }
    @media all and (max-width: 900px) {
      .caselist-item__customer-name {
        display: none; } }
  .caselist-item__panelsettype {
    display: block;
    opacity: .75;
    font-size: 0.6875em;
    color: #303133;
    letter-spacing: 0.04545em;
    margin-left: 20px;
    text-align: left; }
    @media all and (max-width: 900px) {
      .caselist-item__panelsettype {
        display: none; } }
  .caselist-item__interactions {
    display: block;
    padding-top: 6.6px;
    text-align: left;
    padding-left: 20px;
    color: #9070a1; }
    @media all and (max-width: 900px) {
      .caselist-item__interactions {
        padding-top: 0; } }
    .caselist-item__interactions .interaction-button {
      margin-right: 5px; }
    @media all and (max-width: 900px) {
      .caselist-item__interactions .interaction-icon {
        display: block;
        margin-right: 0; }
        .caselist-item__interactions .interaction-icon:nth-child(n+2) {
          margin-top: 5px; } }
  .caselist-item__badge {
    background-color: #a0cdd1;
    size: 11px; }

@keyframes zoomIn {
  from {
    transform: translateX(-500px);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); }
  40% {
    opacity: 1; } }
