.icon-wrapper {
  position: relative;
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  line-height: 0;
  box-sizing: border-box; }
  .icon-wrapper svg {
    width: 100%;
    height: 100%;
    pointer-events: none;
    fill: currentColor; }
