.sidebar {
  position: relative;
  display: flex;
  width: 200px;
  flex-direction: column;
  box-sizing: border-box; }
  @media all and (max-width: 900px) {
    .sidebar {
      width: auto; } }
  .sidebar__addcase {
    padding: 13.2px;
    font-size: 0.75em;
    text-align: center;
    flex: 0 0 auto; }

.stage-list {
  flex: 1 1 auto; }
  .stage-list::after {
    position: absolute;
    content: '';
    width: 1px;
    height: 100%;
    top: 0;
    right: 0;
    background-color: #a9a1ce; }

.stage-group {
  position: relative;
  padding: 0;
  padding-bottom: .1px;
  margin: 0;
  list-style-type: none;
  border-top: 1px solid #a9a1ce;
  background: transparent; }
  .stage-group--new .caselist-item {
    padding: 10px; }
    .stage-group--new .caselist-item::after, .stage-group--new .caselist-item::before {
      display: none; }
  .stage-group--active .caselist-item {
    background: linear-gradient(to right, rgba(255, 255, 255, 0.75) 0%, rgba(255, 255, 255, 0) 99%); }
  .stage-group .caselist-item:not(:last-child) {
    margin-bottom: 4px; }
  .stage-group:last-child .caselist-item--taskmanager-open:last-child {
    border-bottom: 1px solid #a9a1ce; }
  .stage-group .caselist-item--taskmanager-open:not(:last-child) {
    border-bottom: 1px solid #a9a1ce; }
  .stage-group .caselist-item--taskmanager-open:not(:first-child) {
    border-top: 1px solid #a9a1ce; }
