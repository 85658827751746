.divider {
  position: absolute;
  z-index: 1;
  background-color: #7bb8b5;
  transition: top 0.15s cubic-bezier(0.075, 0.82, 0.165, 1), left 0.15s cubic-bezier(0.075, 0.82, 0.165, 1);
  box-sizing: border-box; }
  .divider::after {
    position: absolute;
    content: ''; }
  .divider--horizontal {
    width: 1px;
    cursor: ew-resize; }
    .divider--horizontal.divider--gutter-resize {
      margin-top: 10px; }
      .divider--horizontal.divider--gutter-resize::before {
        position: absolute;
        top: 50%;
        left: 0;
        width: 3px;
        height: 40px;
        margin-top: -20px;
        margin-left: -1px; }
    .divider--horizontal::after {
      width: 10px;
      height: 100%;
      margin-left: -5px; }
  .divider--vertical {
    height: 1px;
    cursor: ns-resize; }
    .divider--vertical.divider--gutter-resize {
      margin-left: 10px; }
      .divider--vertical.divider--gutter-resize::before {
        position: absolute;
        top: 0;
        left: 50%;
        width: 40px;
        height: 3px;
        margin-top: -1px;
        margin-left: -20px; }
    .divider--vertical::after {
      width: 100%;
      height: 10px;
      margin-top: -5px; }
  .divider--gutter-resize {
    background-color: transparent; }
    .divider--gutter-resize::before {
      content: '';
      z-index: 1;
      border-radius: 2px;
      background-color: #fff; }
