.action-button {
  position: relative;
  display: flex;
  padding: .8em;
  margin: auto;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1;
  cursor: pointer;
  outline: none;
  border: 0;
  border-radius: 50%;
  flex-direction: row;
  align-items: center;
  justify-content: center; }
  .action-button--reverse {
    flex-direction: row-reverse; }
    .action-button--reverse.action-button--text.action-button--icon .icon-wrapper {
      margin-left: .7em;
      margin-right: 0 !important; }
  .action-button--fullsize {
    height: 100%;
    width: 100%; }
  .action-button--fullwidth {
    width: 100%; }
  .action-button--transparent {
    padding: 0;
    border-radius: 0; }
    .action-button--transparent:hover {
      text-decoration: underline; }
    .action-button--transparent.focus-ring:focus, .action-button--transparent:active {
      text-decoration: underline; }
  .action-button--fullwidth, .action-button--fullsize, .action-button--text {
    border-radius: 3px; }
  .action-button--text.action-button--icon .icon-wrapper {
    margin-right: .7em; }
  .action-button--hold {
    color: #fff;
    background: linear-gradient(to top, #404aa6 0%, #4064a6 100%); }
    .action-button--hold.action-button--icon:not(.action-button--text):not(.action-button--fullsize)::after {
      content: '';
      opacity: 0;
      position: absolute;
      top: -6px;
      right: -6px;
      bottom: -6px;
      left: -6px;
      pointer-events: none;
      border: 2px solid #404aa6;
      border-radius: inherit;
      transform: scale(0.8);
      transition: transform 0.15s cubic-bezier(0.39, 0.575, 0.565, 1), opacity 0.15s cubic-bezier(0.39, 0.575, 0.565, 1); }
    .action-button--hold:not(:disabled):hover {
      background: linear-gradient(to top, #4752b8 0%, #476fb8 100%); }
    .action-button--hold.focus-ring:not(:disabled):focus.action-button--icon:not(.action-button--text):not(.action-button--fullsize)::after, .action-button--hold:not(:disabled):active.action-button--icon:not(.action-button--text):not(.action-button--fullsize)::after {
      opacity: .6;
      transform: scale(1); }
    .action-button--hold.focus-ring:not(:disabled):focus.action-button--fullsize, .action-button--hold.focus-ring:not(:disabled):focus.action-button--text, .action-button--hold:not(:disabled):active.action-button--fullsize, .action-button--hold:not(:disabled):active.action-button--text {
      outline: dotted 1px currentColor;
      outline-offset: -3px; }
    .action-button--hold.action-button--raised {
      box-shadow: 0 10px 20px 0 rgba(64, 74, 166, 0.2); }
  .action-button--resume {
    color: #fff;
    background: linear-gradient(to top, #404aa6 0%, #4064a6 100%); }
    .action-button--resume.action-button--icon:not(.action-button--text):not(.action-button--fullsize)::after {
      content: '';
      opacity: 0;
      position: absolute;
      top: -6px;
      right: -6px;
      bottom: -6px;
      left: -6px;
      pointer-events: none;
      border: 2px solid #404aa6;
      border-radius: inherit;
      transform: scale(0.8);
      transition: transform 0.15s cubic-bezier(0.39, 0.575, 0.565, 1), opacity 0.15s cubic-bezier(0.39, 0.575, 0.565, 1); }
    .action-button--resume:not(:disabled):hover {
      background: linear-gradient(to top, #4752b8 0%, #476fb8 100%); }
    .action-button--resume.focus-ring:not(:disabled):focus.action-button--icon:not(.action-button--text):not(.action-button--fullsize)::after, .action-button--resume:not(:disabled):active.action-button--icon:not(.action-button--text):not(.action-button--fullsize)::after {
      opacity: .6;
      transform: scale(1); }
    .action-button--resume.focus-ring:not(:disabled):focus.action-button--fullsize, .action-button--resume.focus-ring:not(:disabled):focus.action-button--text, .action-button--resume:not(:disabled):active.action-button--fullsize, .action-button--resume:not(:disabled):active.action-button--text {
      outline: dotted 1px currentColor;
      outline-offset: -3px; }
    .action-button--resume.action-button--raised {
      box-shadow: 0 10px 20px 0 rgba(64, 74, 166, 0.2); }
  .action-button--muted {
    opacity: .5; }
  .action-button--accept {
    color: #fff;
    background: linear-gradient(to top, #404aa6 0%, #4064a6 100%); }
    .action-button--accept.action-button--icon:not(.action-button--text):not(.action-button--fullsize)::after {
      content: '';
      opacity: 0;
      position: absolute;
      top: -6px;
      right: -6px;
      bottom: -6px;
      left: -6px;
      pointer-events: none;
      border: 2px solid #404aa6;
      border-radius: inherit;
      transform: scale(0.8);
      transition: transform 0.15s cubic-bezier(0.39, 0.575, 0.565, 1), opacity 0.15s cubic-bezier(0.39, 0.575, 0.565, 1); }
    .action-button--accept:not(:disabled):hover {
      background: linear-gradient(to top, #4752b8 0%, #476fb8 100%); }
    .action-button--accept.focus-ring:not(:disabled):focus.action-button--icon:not(.action-button--text):not(.action-button--fullsize)::after, .action-button--accept:not(:disabled):active.action-button--icon:not(.action-button--text):not(.action-button--fullsize)::after {
      opacity: .6;
      transform: scale(1); }
    .action-button--accept.focus-ring:not(:disabled):focus.action-button--fullsize, .action-button--accept.focus-ring:not(:disabled):focus.action-button--text, .action-button--accept:not(:disabled):active.action-button--fullsize, .action-button--accept:not(:disabled):active.action-button--text {
      outline: dotted 1px currentColor;
      outline-offset: -3px; }
    .action-button--accept.action-button--raised {
      box-shadow: 0 10px 20px 0 rgba(64, 74, 166, 0.2); }
  .action-button--reject {
    color: #fff;
    background: linear-gradient(to top, #e04341 0%, #e04167 100%); }
    .action-button--reject.action-button--icon:not(.action-button--text):not(.action-button--fullsize)::after {
      content: '';
      opacity: 0;
      position: absolute;
      top: -6px;
      right: -6px;
      bottom: -6px;
      left: -6px;
      pointer-events: none;
      border: 2px solid #e04341;
      border-radius: inherit;
      transform: scale(0.8);
      transition: transform 0.15s cubic-bezier(0.39, 0.575, 0.565, 1), opacity 0.15s cubic-bezier(0.39, 0.575, 0.565, 1); }
    .action-button--reject:not(:disabled):hover {
      background: linear-gradient(to top, #e45957 0%, #e45778 100%); }
    .action-button--reject.focus-ring:not(:disabled):focus.action-button--icon:not(.action-button--text):not(.action-button--fullsize)::after, .action-button--reject:not(:disabled):active.action-button--icon:not(.action-button--text):not(.action-button--fullsize)::after {
      opacity: .6;
      transform: scale(1); }
    .action-button--reject.focus-ring:not(:disabled):focus.action-button--fullsize, .action-button--reject.focus-ring:not(:disabled):focus.action-button--text, .action-button--reject:not(:disabled):active.action-button--fullsize, .action-button--reject:not(:disabled):active.action-button--text {
      outline: dotted 1px currentColor;
      outline-offset: -3px; }
    .action-button--reject.action-button--raised {
      box-shadow: 0 10px 20px 0 rgba(224, 67, 65, 0.2); }
  .action-button--primary {
    color: #fff;
    background: linear-gradient(to top, #8b80bc 0%, #8084bc 100%); }
    .action-button--primary.action-button--icon:not(.action-button--text):not(.action-button--fullsize)::after {
      content: '';
      opacity: 0;
      position: absolute;
      top: -6px;
      right: -6px;
      bottom: -6px;
      left: -6px;
      pointer-events: none;
      border: 2px solid #8b80bc;
      border-radius: inherit;
      transform: scale(0.8);
      transition: transform 0.15s cubic-bezier(0.39, 0.575, 0.565, 1), opacity 0.15s cubic-bezier(0.39, 0.575, 0.565, 1); }
    .action-button--primary:not(:disabled):hover {
      background: linear-gradient(to top, #9a91c5 0%, #9194c5 100%); }
    .action-button--primary.focus-ring:not(:disabled):focus.action-button--icon:not(.action-button--text):not(.action-button--fullsize)::after, .action-button--primary:not(:disabled):active.action-button--icon:not(.action-button--text):not(.action-button--fullsize)::after {
      opacity: .6;
      transform: scale(1); }
    .action-button--primary.focus-ring:not(:disabled):focus.action-button--fullsize, .action-button--primary.focus-ring:not(:disabled):focus.action-button--text, .action-button--primary:not(:disabled):active.action-button--fullsize, .action-button--primary:not(:disabled):active.action-button--text {
      outline: dotted 1px currentColor;
      outline-offset: -3px; }
    .action-button--primary.action-button--raised {
      box-shadow: 0 10px 20px 0 rgba(139, 128, 188, 0.2); }
  .action-button--negative {
    color: #404aa6;
    background: linear-gradient(to top, #fff 0%, white 100%); }
    .action-button--negative.action-button--icon:not(.action-button--text):not(.action-button--fullsize)::after {
      content: '';
      opacity: 0;
      position: absolute;
      top: -6px;
      right: -6px;
      bottom: -6px;
      left: -6px;
      pointer-events: none;
      border: 2px solid #fff;
      border-radius: inherit;
      transform: scale(0.8);
      transition: transform 0.15s cubic-bezier(0.39, 0.575, 0.565, 1), opacity 0.15s cubic-bezier(0.39, 0.575, 0.565, 1); }
    .action-button--negative:not(:disabled):hover {
      background: linear-gradient(to top, white 0%, white 100%); }
    .action-button--negative.focus-ring:not(:disabled):focus.action-button--icon:not(.action-button--text):not(.action-button--fullsize)::after, .action-button--negative:not(:disabled):active.action-button--icon:not(.action-button--text):not(.action-button--fullsize)::after {
      opacity: .6;
      transform: scale(1); }
    .action-button--negative.focus-ring:not(:disabled):focus.action-button--fullsize, .action-button--negative.focus-ring:not(:disabled):focus.action-button--text, .action-button--negative:not(:disabled):active.action-button--fullsize, .action-button--negative:not(:disabled):active.action-button--text {
      outline: dotted 1px currentColor;
      outline-offset: -3px; }
    .action-button--negative.action-button--raised {
      box-shadow: 0 10px 20px 0 rgba(255, 255, 255, 0.2); }
  .action-button--settings {
    color: #fff;
    background: linear-gradient(to top, #7bb8b5 0%, #7bb8a6 100%); }
    .action-button--settings.action-button--icon:not(.action-button--text):not(.action-button--fullsize)::after {
      content: '';
      opacity: 0;
      position: absolute;
      top: -6px;
      right: -6px;
      bottom: -6px;
      left: -6px;
      pointer-events: none;
      border: 2px solid #7bb8b5;
      border-radius: inherit;
      transform: scale(0.8);
      transition: transform 0.15s cubic-bezier(0.39, 0.575, 0.565, 1), opacity 0.15s cubic-bezier(0.39, 0.575, 0.565, 1); }
    .action-button--settings:not(:disabled):hover {
      background: linear-gradient(to top, #8cc1be 0%, #8cc1b1 100%); }
    .action-button--settings.focus-ring:not(:disabled):focus.action-button--icon:not(.action-button--text):not(.action-button--fullsize)::after, .action-button--settings:not(:disabled):active.action-button--icon:not(.action-button--text):not(.action-button--fullsize)::after {
      opacity: .6;
      transform: scale(1); }
    .action-button--settings.focus-ring:not(:disabled):focus.action-button--fullsize, .action-button--settings.focus-ring:not(:disabled):focus.action-button--text, .action-button--settings:not(:disabled):active.action-button--fullsize, .action-button--settings:not(:disabled):active.action-button--text {
      outline: dotted 1px currentColor;
      outline-offset: -3px; }
    .action-button--settings.action-button--raised {
      box-shadow: 0 10px 20px 0 rgba(123, 184, 181, 0.2); }
  .action-button--effective {
    color: #fff;
    background: linear-gradient(to top, #8489c3 0%, #8499c3 100%); }
    .action-button--effective.action-button--icon:not(.action-button--text):not(.action-button--fullsize)::after {
      content: '';
      opacity: 0;
      position: absolute;
      top: -6px;
      right: -6px;
      bottom: -6px;
      left: -6px;
      pointer-events: none;
      border: 2px solid #8489c3;
      border-radius: inherit;
      transform: scale(0.8);
      transition: transform 0.15s cubic-bezier(0.39, 0.575, 0.565, 1), opacity 0.15s cubic-bezier(0.39, 0.575, 0.565, 1); }
    .action-button--effective:not(:disabled):hover {
      background: linear-gradient(to top, #9599cb 0%, #95a7cb 100%); }
    .action-button--effective.focus-ring:not(:disabled):focus.action-button--icon:not(.action-button--text):not(.action-button--fullsize)::after, .action-button--effective:not(:disabled):active.action-button--icon:not(.action-button--text):not(.action-button--fullsize)::after {
      opacity: .6;
      transform: scale(1); }
    .action-button--effective.focus-ring:not(:disabled):focus.action-button--fullsize, .action-button--effective.focus-ring:not(:disabled):focus.action-button--text, .action-button--effective:not(:disabled):active.action-button--fullsize, .action-button--effective:not(:disabled):active.action-button--text {
      outline: dotted 1px currentColor;
      outline-offset: -3px; }
    .action-button--effective.action-button--raised {
      box-shadow: 0 10px 20px 0 rgba(132, 137, 195, 0.2); }
  .action-button__text {
    padding-top: .16em;
    vertical-align: middle; }
  .action-button:disabled {
    opacity: .5 !important;
    cursor: not-allowed; }
  .action-button .icon-wrapper {
    height: 1.5em;
    width: 1.5em; }
