.usermessage {
  background-color: #d8d8da;
  font-size: 8px;
  margin-top: 1px;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 1em;
  padding-right: 1em;
  line-height: 3em;
  display: flex;
  align-items: center; }
  .usermessage h1 {
    margin: 0.3em;
    padding: 0; }
  .usermessage--timestamp {
    margin-right: 2em;
    display: inline-block; }
  .usermessage--text {
    display: inline-flex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 12em; }
  .usermessage--button {
    font-size: 9px;
    margin-right: 3em;
    position: absolute;
    right: 1em; }
