.rolldown {
  position: relative;
  display: inline-block;
  font-size: 0.75em;
  white-space: nowrap; }
  .rolldown > .action-button {
    border: 0px solid currentColor;
    border-radius: 0%;
    align-items: baseline; }
  .rolldown--open > .action-button {
    border-color: #fff;
    background-color: #fff;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }
  .rolldown--open .rolldown__panel {
    display: block; }
  .rolldown__panel {
    position: absolute;
    right: 0px;
    display: none;
    min-width: 100%;
    padding: 0 10px;
    z-index: 2;
    border: 1px solid #fff;
    background-color: #fff;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-sizing: border-box; }
    .rolldown__panel .action-button {
      width: 100%;
      margin: 10px 0;
      text-align: left;
      justify-content: flex-start; }
